<template>
  <!-- 聊天客服->图片消息 -->
  <!-- <img :src="text" alt class="pictures" /> -->
  <el-image :src="text" class="pictures" :preview-src-list="srcList">
  </el-image>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {
      srcList: [this.text],
    };
  },
};
</script>

<style lang="less" scoped>
.pictures {
  width: 250px;
  height: auto;
  display: block;
}
</style>
