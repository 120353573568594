<template>
  <div class="content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="密码登录" name="password-login">
        <!--        <PasswordLogin :activeName="activeName"></PasswordLogin>-->
      </el-tab-pane>
      <el-tab-pane label="短信登录" name="short-message-login"> </el-tab-pane>
      <PasswordLogin :activeName="activeName"></PasswordLogin>
    </el-tabs>
  </div>
</template>

<script>
import PasswordLogin from "./password-login";
// let indexs = tab.index;
export default {
  components: {
    PasswordLogin,
  },
  data() {
    return {
      activeName: "password-login",
    };
  },
  methods: {
    handleClick(tab) {
      // let indexs = tab.index;
      // if (indexs === 0) {
      //   this.activeName = "password-login";
      // } else {
      //   this.activeName = "short-message-login";
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 659px;
  height: 750px;
  background: #ffffff;
  margin: 0 auto;
  box-shadow: 0px 0px 6px rgba(196, 194, 194, 0.53);
  border-radius: 20px;
  overflow: hidden;
  /deep/ .el-tabs__nav-scroll {
    margin: 77px 0 61px 72px;
  }
  /deep/ .el-tabs__item.is-active {
    font-size: 28px;
    font-weight: 500;
    margin: 0 0 9px 0;
  }
  /deep/ .el-tabs__nav-wrap::after {
    background: #ffffff;
  }
  /deep/ .el-tabs__active-bar {
    height: 4px;
  }
  /deep/ .el-tabs__item {
    font-size: 28px;
    font-weight: 500;
  }
}
</style>
