<template>
  <div class="login-page">
    <div class="left">
      <img src="../../../assets/logo/logo.png" alt class="logo" />
      <img src="../../../assets/login-page-chart/icon.png" alt class="icon" />
    </div>
    <div class="right">
      <div class="welcome-login">
        <span>欢迎登录</span>
        <span>后台客服</span>
      </div>
      <LoginContent></LoginContent>
    </div>
  </div>
</template>

<script>
import LoginContent from './modules/login-content'
export default {
  components: {
    LoginContent
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.login-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background: palevioletred;
  display: flex;
  .left {
    width: 45%;
    height: 100vh;
    background: #f8f9ff;
    .logo {
      width: 348px;
      height: 69px;
      display: block;
      margin: 41px 0 65px 57px;
    }
    .icon {
      width: 608px;
      height: 770px;
      display: block;
      margin: 0 auto 0;
    }
  }
  .right {
    width: 55%;
    height: 100vh;
    background: #ffffff;
    .welcome-login {
      height: 45px;
      margin: 80px 0 42px 210px;
      span:nth-child(1) {
        font-size: 32px;
        font-weight: 500;
        color: #333;
      }
      span:nth-child(2) {
        font-size: 24px;
        font-weight: 400;
        color: #666;
        margin: 0 0 0 33px;
      }
    }
  }
}
</style>

