<template>
  <!--  <van-uploader-->
  <!--    :after-read="afterRead"-->
  <!--    :before-read="beforeRead"-->
  <!--    v-model="fileList"-->
  <!--    upload-icon="plus"-->
  <!--    multiple-->
  <!--    class="uploader"-->
  <!--    @delete="onDelete"-->
  <!--  />-->
  <input type="file" ref="filElem" @change="afterRead">
</template>

<script>
import oss from "ali-oss";
import {getAliSts} from "@/api/user";
import tools from "@/common/js/tools";

export default {
  name: "oss-img",
  props: {
    emptyImg: {},
  },
  data() {
    return {
      aliOss: "",
      fileList: [],
      imgArr: [],
    };
  },
  watch: {
    emptyImg(newVal, oldVal) {
      this.fileList = newVal;
      console.log("-----回显------", this.fileList);
      this.fileList.forEach((item) => {
        this.imgArr.push(item.url);
      });
    },
  },
  async mounted() {

  },
  methods: {
    async startImg() {
      let ret = await getAliSts({});
      if (ret.code * 1 === 1) {
        // console.log("----------------");
        this.aliOss = new oss({
          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
          region: "oss-cn-chengdu",
          // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
          accessKeyId: ret.data.AccessKeyId,
          accessKeySecret: ret.data.AccessKeySecret,
          // 从STS服务获取的安全令牌（SecurityToken）。
          stsToken: ret.data.SecurityToken,
          refreshSTSToken: async () => {
            // 向您搭建的STS服务获取临时访问凭证。
            let ret = await getAliSts({});
            if (ret.code * 1 === 1) {
              return {
                accessKeyId: ret.info.accessKeyId,
                accessKeySecret: ret.info.accessKeySecret,
                stsToken: ret.info.stsToken,
              };
            } else {
              tools.error(ret.msg);
            }
          },
          // 刷新临时访问凭证的时间间隔，单位为毫秒。
          refreshSTSTokenInterval: 800000,
          // 填写Bucket名称。
          bucket: "jhnewshop",
        });
        // console.log(this.aliOss);
        this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
      } else {
        tools.error(ret.msg);
      }
    },
    beforeRead(fileArr) {
      if (Array.isArray(fileArr)) {
        if (fileArr.length > 1) {
          tools.error("图片仅支持单张上传");
          this.fileList = [];
          return false;
        } else {
          return true;
        }
      }
      if (fileArr.type !== "image/jpeg" && fileArr.type !== "image/png") {
        tools.error("文件格式不正确");
        this.fileList = [];
        return false;
      }
      return true;
    },
    async afterRead() {
      // this.$emit("getImages", 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2Fa%2F5785edfd8323c.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1641543839&t=a8b97771066d11c846206d6b7082742b');
      // return ;
      // console.log(this.$refs.filElem.files);
      if (this.$refs.filElem.files[0] === undefined) {
        return false;
      }
      let file = this.$refs.filElem.files[0];
      if (this.aliOss === "") {
        tools.error("上传主键正在初始化中....");
        return false;
      }
      let path = this.getFilPathName(file);
      const r1 = await this.aliOss.put(path, file);
      console.log('--------------------------------')
      console.log(r1)
      if (r1.url !== undefined) {
        let url = r1.url;
        this.imgArr.push(
          `${url}?x-oss-process=image/auto-orient,1/quality,q_85`
        );
        this.$emit("getImages", url);
      }
    },
    onDelete(fileObj, data) {
      this.imgArr.splice(data.index, 1);
      // let imgArr = [];
      // this.fileList.forEach((item) => {
      //   imgArr.push(item.url);
      // });
      this.$emit("getImages", this.imgArr);
    },
    getFilPathName(file) {
      let date = new Date();
      let path =
        "user/" +
        date.getYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "/" +
        date.getMilliseconds();
      path += file.name;
      return path;
    },

  },
};
</script>

<style lang="less" scoped>
/deep/ .van-uploader__upload {
  width: 120px;
  height: 120px;
}

/deep/ .van-uploader__preview-image {
  width: 120px;
  height: 120px;
}

.uploader {
  padding-bottom: 49px;
}
</style>
