import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/flexible.js'
import { Button, Select, Tabs, TabPane, Input, Step, Steps, Icon, Badge, Image, } from 'element-ui';
// import { elementUi} from 'element-ui';
Vue.use(Button);
Vue.use(Select);
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Input)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Icon)
Vue.use(Image)
Vue.use(Badge)

// Vue.use(elementUi)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
