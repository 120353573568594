import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, getChatId, setChatId, setToken } from '@/utils/auth'
import { login } from '../api/user.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken() || '',
    chatId: getChatId() || '',
  },
  mutations: {
    setToken(state, data) {
      setToken(data.token)
      state.token = data.token
    },
    setChatId(state, data) {
      setChatId(data.m_id)
      state.m_id = data.m_id
    }
  },
  actions: {
    async login({ commit }, data) {
      const res = await login(data)
      console.log('登录------------', res);
      console.log(res.data);
      commit('setToken', res.data)
      commit('setChatId', res.data)
      return res
    }
  },
})
