<template>
  <!-- 找回密码流程 -->
  <div class="package">
    <div class="contents">
      <div v-if="active === 0">
        <el-input
          v-model="cellPhoneNumber"
          placeholder="请输入手机号"
          class="input"
          oninput="value=value.replace(/[^\d]/g,'')"
        ></el-input>
        <el-input
          v-model="smsVerificationCode"
          placeholder="请输入短信验证码"
          class="short-message-code"
          oninput="value=value.replace(/[^\d]/g,'')"
        ></el-input>
        <el-button type="text" class="buttons" @click="getCode" v-if="time <= 0"
          >获取验证码</el-button
        >
        <el-button type="text" class="buttons" v-else>{{ time }}s</el-button>
      </div>
      <div v-else-if="active === 1">
        <el-input
          v-model="password"
          placeholder="请输入新密码"
          class="input"
          show-password
          oninput="value=value.replace(/[^\d]/g,'')"
        ></el-input>
        <el-input
          v-model="passwords"
          placeholder="请再次输入新密码"
          class="inputs"
          show-password
          oninput="value=value.replace(/[^\d]/g,'')"
        ></el-input>
      </div>
      <div class="complete" v-else>
        <!-- <i class="el-icon-circle-check"></i> -->
        <img src="~@/assets/forget-password/Finished.png" alt />
        <p>恭喜你找回密码成功</p>
        <!-- <el-button type="primary" class="return" @click="ReturnLogin">返回登录</el-button> -->
      </div>
      <el-button type="primary" class="next" @click="next">{{
        active >= 2 ? "返回登录" : "下一步"
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { reset, check, send } from "@/api/user";
import tools from "@/common/js/tools";

export default {
  props: {
    active: {
      type: Number,
    },
  },
  data() {
    return {
      cellPhoneNumber: "",
      smsVerificationCode: "",
      password: "",
      passwords: "",
      dode: false,
      time: 0,
      //   modifyStatus: 1
    };
  },
  mounted() {},
  methods: {
    forget() {
      this.$router.push("/forgetpassword");
    },
    next() {
      if (this.active === 0) {
        this.verifyCode();
      } else if (this.active === 1) {
        // code === 1
        this.confirm();
      } else {
        this.$emit("nextStep");
      }
    },
    async verifyCode() {
      if (this.smsVerificationCode === "") {
        tools.error("请输入验证码");
        return;
      }
      const result = await check({
        phone: this.cellPhoneNumber,
        code: this.smsVerificationCode,
      });
      if (result.code * 1 === 1) {
        this.$emit("nextStep");
      } else {
        tools.error(result.msg);
      }
    },
    async confirm() {
      if (this.password === "" || this.passwords === "") {
        tools.error("请设置密码");
        return;
      }
      if (this.password != this.passwords) {
        tools.error("两次密码不一致");
        return;
      }
      const result = await reset({
        phone: this.cellPhoneNumber,
        password: this.password,
        again_password: this.passwords,
      });
      console.log(result);
      if (result.code === 1) {
        if (result.again_password === result.password) {
          this.$emit("nextStep");
        } else {
          tools.error(result.msg);
        }
      }
    },
    async getCode() {
      if (this.cellPhoneNumber === "") {
        tools.error("请输入手机号码");
        return;
      }
      const result = await send({
        phone: this.cellPhoneNumber,
        send_type: "reset",
      });
      console.log("请求数据---------------", result);
      // if(){},
      if (this.time === 0) {
        tools.success("发送成功");
        this.time = 60;
        let that = this;
        const timer = setInterval(() => {
          that.time--;
          if (that.time === 0) {
            clearInterval(timer);
          }
        }, 1000);
      } else {
        tools.error("验证码获取失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.package {
  .contents {
    width: 659px;
    height: auto;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0px 0px 6px rgba(196, 194, 194, 0.53);
    /deep/ .el-input__inner {
      height: 81px;
      background: #f7f8fa;
      border: none;
      border-radius: 41px;
      padding-left: 36px;
      font-size: 24px;
    }
    .input {
      width: 515px;
      margin: 104px 0 51px 72px;
    }
    .inputs {
      width: 515px;
      margin: 0 0 103px 72px;
    }
    .button {
      color: #333333;
      font-size: 24px;
      margin: 31px 0 73px 50px;
      display: block;
    }
    .next {
      width: 514px;
      height: 81px;
      border-radius: 40px;
      font-size: 24px;
      color: #fff;
      margin: 0 0 103px 72px;
      background: linear-gradient(129deg, #4b86fa 0%, #3f7fff 100%);
    }
    .short-message-code {
      width: 345px;
      height: 81px;
      margin: 0 45px 130px 72px;
      display: inline-block;
    }
    .buttons {
      color: #333333;
      font-size: 24px;
    }
  }
  .complete {
    width: 514px;
    height: auto;
    // background: pink;
    margin: 140px auto 0;
    text-align: center;
    i {
      font-size: 81px;
      background: #4281fe;
      border-radius: 50%;
    }
    p {
      font-size: 24px;
      color: #333;
      margin: 41px 0 74px 0;
    }
    .return {
      width: 514px;
      height: 81px;
      background: linear-gradient(129deg, #4b86fa 0%, #3f7fff 100%);
      border-radius: 41px;
      font-size: 24px;
      font-size: 400;
      color: #fff;
    }
  }
}
/deep/ input::-webkit-input-placeholder {
  color: #999999;
  font-size: 24px;
  padding: 0 0 0 35px;
  font-weight: 400;
}
// input::-moz-placeholder {
//   color: red;
// }
// input::-moz-input-placeholder {
//   color: red;
// }
// input::-ms-input-placeholder {
//   color: red;
// }
</style>
