<template>
<!-- 聊天客服->文本消息 -->
  <span>{{text}}</span>
</template>

<script>
export default {
  props:['text']
};
</script>

<style lang="less" scoped>
span {
  color: #333;
  font-size: 20px;
  word-wrap:break-word;
  padding: 0 !important;
  // padding: 0 20px 0 20px;
}
</style>
