<template>
  <div class="chat-page">
    <div class="chat-list">
      <el-input
        placeholder="搜索"
        prefix-icon="el-icon-search"
        v-model="search"
        class="search"
      ></el-input>
      <ul v-if="list.length > 0" ref="groupList">
        <li
          v-show="item.isShow"
          :class="{ 'contacts-list': item.id === num }"
          v-for="(item, index) in list"
          :key="index"
          @click="setImItem(index)"
        >

        <!-- v-if="item.wait_read > 0" -->
          <el-badge
            :value="item.wait_read>99?'99+':item.wait_read"
            :max="99"
            class="badge"
            v-if="item.wait_read > 0"
          ></el-badge>
          <img :src="item.logo" alt class="head-portrait" />
          <span class="customerName">{{ item.name }}</span>
          <span class="time">{{ item.change_time }}</span>
          <p class="list-news">{{ item.text.startsWith('http')?'[图片]':item.text}}</p>
        </li>
      </ul>
      <div v-else class="blank">
        <div class="blanks">
          <img src="@/assets/chat/blanks.png" alt="" />
          <span>暂无对话列表</span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="news-top">
        <el-badge
          :value="valueSum"
          :max="99"
          class="badge"
          v-if="valueSum > 0"
        ></el-badge>
        <img class="sums" src="@/assets/chat/service2_icon.png" alt />
        <div class="sign-out" @click="out">
          <img src="@/assets/chat/exit_icon2.png" alt />
          <span class="return">退出</span>
        </div>
      </div>
      <contents
        ref="imItem"
        :socket="socket"
        @startImGroup="startImGroup"
        @newImGroup="newImGroup"
      ></contents>
    </div>
  </div>
</template>

<script>
import contents from "@/views/chat/modules/content/content.vue";
import socket from "@/common/js/socket";
import tools from "@/common/js/tools";

export default {
  components: {
    contents,
  },
  data() {
    return {
      search: "",
      value: 4,
      num: 0,
      valueSum: "",
      list: [],
      page: 1,
      isEnd: 0,
      socket: socket,
      isAjax: false,
      isOne: true,
      examId: null,
      groupIds:[]
    };
  },
  watch: {
    search: function () {
      this.list.forEach((value, key) => {
        if (value.name.indexOf(this.search) !== -1) {
          value.isShow = true;
        } else {
          value.isShow = false;
        }
      });
    },
  },
  mounted() {
    //事件注册
    let _that = this;

    //初始化聊天组信息
    socket.on("startImGroup", (ret) => {
      console.log("--------------" + ret.status);
      if (ret.status * 1 === 1) {
        this.startImGroup();
      } else {
        tools.error(ret.msg);
      }
    });
    //绑定获取消息通知
    socket.on("getImGroup", (ret) => {
      if (ret.status * 1 === 1) {
        _that.setMsgGroup(ret.data);
        _that.sums();
      } else {
        tools.error(ret.msg);
      }
    });
    socket.on("getGroupDetails", (ret) => {
      console.log("--------------" + ret.code);
      if (ret.status * 1 === 1) {
        this.setGroupDetails(ret.data);
      } else {
        tools.error(ret.msg);
      }
    });

    this.getMsgGroup();
    setTimeout(() => {
      this.pagingGroup();
    }, 1000);
  },
  methods: {
    setGroupDetails(group){
      console.log(group);
      let isAdd=this.groupIds.indexOf(group.id);
      console.log('--------------序号'+isAdd)
      if (this.search==='' || group.name.indexOf(this.search) !== -1) {
        group.isShow = true;
      } else {
        group.isShow = false;
      }
      if(isAdd<=-1){
        this.groupIds.unshift(group.id)
        this.list.unshift(group)
      }else {
        if(group.wait_read>0){
          if(isAdd>0) {
            this.groupIds.splice(isAdd,1);
            this.list.splice(isAdd,1);
            this.groupIds.unshift(group.id);
            this.list.unshift(group);
          }else {
            this.list[0]=group;
          }
        }else {
          this.list[isAdd].text=group.text;
          this.list[isAdd].text_type=group.textType;
          this.list[isAdd].wait_read=0;
        }
      }
      console.log(this.list)
    },
    newImGroup(data){
      socket.emit("getGroupDetails",data)
    },
    //总消息
    sums() {
      let num = 0;
      this.list.forEach((item, index) => {
        num += item.wait_read;
      });
      this.valueSum = num;
    },
    startImGroup() {
      this.page = 1;
      this.isEnd = 0;
      this.isAjax = false;
      this.getMsgGroup();
    },
    out() {
      this.$router.push({
        path: "/",
        // name: "sy",
        // query:{news:'valueSum'}
      });
    },
    setMsgGroup(data) {
      //搜索
      if (data.length > 0) {
        data.forEach((value, key) => {
          if (value.name.indexOf(this.search) !== -1) {
            value.isShow = true;
          } else {
            value.isShow = false;
          }
        });

        data.forEach((value)=>{
          let isAdd=this.groupIds.indexOf(value.id);
          if(isAdd<=-1){
            this.groupIds.push(value.id)
            this.list.push(value)
          }
        });

        // if (this.page * 1 === 1) {
        //   this.list = data;
        // } else {
        //   this.list = [...this.list, ...data];
        // }
      } else {
        this.isEnd = 1;
      }
      if (this.isOne) {
        //初始化第一个聊天对话框
        this.isOne = false;
        if (this.list.length > 0) this.setImItem(0);
      }
      ++this.page;
      this.isAjax = false;
    },
    getMsgGroup() {
      if (this.isEnd === 1) {
        return false;
      }
      socket.emit("getImGroup", {
        page: this.page,
        group_type: 3,
      });
    },
    setImItem(index) {
      this.num = this.list[index].id;
      this.$refs.imItem.setIm(this.list[index].id);
    },
    pagingGroup() {
      let uls = this.$refs.groupList;
      const load = uls.scrollHeight / 2 - uls.scrollTop;
      if (uls.scrollTop > load) {
        // console.log("ok");
        this.getMsgGroup();
      }
      if (this.isAjax) {
        return;
      }
      this.isAjax = true;
      // this.getMsgGroup();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  flex: 1;
}
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
.chat-page {
  display: flex;
  width: 100%;
  height: auto;
  overflow: hidden;
  .chat-list {
    width: 384px;
    height: 100vh;
    // background: cornflowerblue;
    // float: left;
    border-right: 1px solid #e4e4e4;
    .blank {
      width: 100%;
      height: 100%;

      .blanks {
        width: 150px;
        height: auto;
        text-align: center;
        margin: 85% auto;
        img {
          width: 150px;
          display: block;
        }
      }
    }
    .search {
      width: 349px;
      height: 41px;
      display: flex;
      left: 18px;
      top: 20px;
    }

    ul {
      width: 100%;
      height: calc(100vh - 81px);
      margin: 40px 0 0 0;
      overflow: auto;

      .contacts-list {
        background-color: #dedede;
      }

      li {
        width: 100%;
        height: 105px;
        // background: palegreen;
        margin: 0 0 8px 0;
        position: relative;

        .badge {
          position: absolute;
          left: 72px;
          top: 10px;
          z-index: 100;
          ::v-deep .el-badge__content{
            min-width: 24px;
            height: 24px;
            line-height: 24px;
            padding: 2px;
            background-color: #FF3333;
            border-radius: 50%;
          }
        }

        .head-portrait {
          width: 65px;
          height: 65px;
          margin: 20px 0 20px 20px;
          // background: blue
          border-radius: 5px;
        }

        .customerName {
          position: absolute;
          left: 100px;
          top: 17px;
          font-size: 20px;
          font-weight: 500;
          width: 100px;
          overflow: hidden;   /* 超出隐藏 */
          white-space:nowrap;  /* 强制不自动换行 */
          text-overflow:ellipsis; /* 用...显示 */

        }

        .time {
          width: 125px;
          height: 19px;
          //   background: blueviolet;
          display: block;
          position: absolute;
          right: 19px;
          top: 20px;
          line-height: 19px;
          text-align: right;
          font-size: 16px;
          color: #888888 ;
        }

        .list-news {
          width: 68%;
          height: 22px;
          display: block;
          //   background: brown;
          position: absolute;
          right: 19px;
          bottom: 22px;
          line-height: 18px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 18px;
          color: #888888 ;
        }

        &:hover {
          background-color: #dedede;
        }
      }
    }
  }

  .news-top {
    // width: calc(100% - 385px);
    width: 100%;
    height: 80px;
    float: right;
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    .sums {
      // display: block;
      margin: 26px 0 0 749px;
    }
    .badge {
      position: absolute;
      left: 770px;
      top: 25px;
    }

    .sign-out {
      width: 100px;
      height: 24px;
      // background: burlywood;
      float: right;
      margin: 28px 10px 0 0;
      // text-align: right;
      img {
        margin-right: 10px;
        float: left;
      }

      .return {
        font-size: 18px;
        color: #333;
        font-weight: 400;
      }
    }

    .sign-out:hover {
      cursor: pointer;
    }
  }

  .chat-content {
    // width: calc(100% - 385px);
    // height: calc(100vh - 81px);
    // float: right;
    width: 100%;
    .userName {
      width: 100%;
      height: 64px;
      overflow: hidden;
      border-bottom: 1px solid #e4e4e4;

      span {
        font-size: 24px;
        color: #333;
        font-weight: bold;
        display: block;
        margin: 15px 0 0 48px;
      }
    }

    .content {
      width: 100%;
      height: calc(100vh - 349px);
      overflow: auto;

      ul {
        li {
          width: 100%;
          height: auto;
          // background: pink;
          margin: 0 0 48px 0;
        }
      }
    }

    .input-box {
      width: 100%;
      height: 202px;
      // background: pink;
      border-top: 1px solid #e4e4e4;

      .send-map {
        width: 100%;
        height: 72px;
        // background: olive;
        overflow: hidden;

        .send-pictures {
          width: 32px;
          height: 32px;
          margin: 20px 0 0 41px;

          img {
            width: 32px;
            height: 32px;
            display: block;
          }
        }

        .send-pictures:hover {
          background: #ccc;
        }
      }

      .inputs {
        width: 100%;
        height: 130px;
        border: none;
      }

      // /deep/ .el-input__inner {
      //   width: 100%;
      //   height: 130px;
      //   border: none;
      // }
      /deep/ .el-textarea__inner {
        // width: 100%;
        // height: 130px;
        border: none;
      }
    }
  }
}

.left {
  display: flex;
  .dialogue {
    width: 100%;
    height: 65px;
    .portrait {
      width: 65px;
      height: 65px;
      margin: 0 19px 0 41px;
      float: left;
    }
    .word {
      position: relative;
      width: auto;
      height: auto;
      background: #f3f3f3;
      display: inline-block;
      line-height: 65px;
      max-width: 840px;
      border-radius: 5px;
      .horn {
        position: absolute;
        top: 9px;
        right: 87px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid transparent;
        border-right: 12px solid #f3f3f3;
        border-bottom: 8px solid transparent;
      }
      span {
        color: #333;
        font-size: 24px;
        // margin: 0 0 0 53px;
        padding: 0 20px 0 20px;
      }
    }
  }
  .picture {
    width: 100%;
    height: 234px;
    // margin: 0 35px 0 0;
    .portrait {
      width: 65px;
      height: 65px;
      margin: 0 19px 0 41px;
      float: left;
    }
    .left-chart {
      position: relative;
      width: 236px;
      height: auto;
      background: #f3f3f3;
      display: inline-block;
      .horn {
        position: absolute;
        top: 13px;
        right: 235px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid transparent;
        border-right: 12px solid #f3f3f3;
        border-bottom: 8px solid transparent;
      }
      .pictures {
        width: 100%;
        height: auto;
      }
    }
  }
}

.right {
  display: flex;
  justify-content: flex-end;

  .dialogue {
    width: auto;
    height: 100%;
    // background: chartreuse;
    .portrait {
      width: 65px;
      height: 65px;
      margin: 0 19px 0 41px;
      float: right;
    }

    .word {
      position: relative;
      width: auto;
      height: auto;
      background: #d9f4fd;
      display: inline-block;
      line-height: 65px;
      max-width: 840px;
      border-radius: 5px;

      .horn {
        position: absolute;
        top: 13px;
        right: -19px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid #d9f4fd;
        border-right: 12px solid transparent;
        border-bottom: 8px solid transparent;
      }

      span {
        color: #333;
        font-size: 24px;
        // margin: 0 0 0 53px;
        padding: 0 20px 0 20px;
      }
    }
  }

  .picture {
    width: auto;
    height: 234px;

    .portrait {
      width: 65px;
      height: 65px;
      margin: 0 19px 0 41px;
      float: right;
    }

    .left-chart {
      position: relative;
      width: 236px;
      height: auto;
      background: #d9f4fd;
      display: inline-block;

      .horn {
        position: absolute;
        top: 13px;
        right: -19px;
        width: 0;
        height: 0;
        // border-top: 8px solid transparent;
        // border-left: 8px solid rebeccapurple;
        // border-right: 12px solid transparent;
        // border-bottom: 8px solid transparent;
        border-top: 8px solid transparent;
        border-left: 8px solid #d9f4fd;
        border-right: 12px solid transparent;
        border-bottom: 8px solid transparent;
      }

      .pictures {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
