<template>
  <!-- 忘记密码->找回密码 -->
  <div class="verify-phone">
    <div class="retrieve-password">
      <div class="left">
        <img src="~@/assets/logo/logo.png" class="logo" />
        <span class="span">找回密码</span>
      </div>
      <div class="right" @click="returns">
        <img src="~@/assets/forget-password/exit_icon.png" alt class="exit_icon" />
        <el-button type="text" class="button">返回登录首页</el-button>
      </div>
    </div>
    <div class="step">
      <el-steps :active="active" finish-status="success" class="steps">
        <el-step title="验证手机"></el-step>
        <el-step title="设置密码"></el-step>
        <el-step title="完成"></el-step>
      </el-steps>
    </div>
    <Step :active="active" @nextStep=nextStep></Step>
    <!-- <el-button style="margin-top: 12px;" @click="next">下一步</el-button> -->
  </div>
</template>

<script>
import Step from "./modules/step/step.vue";

export default {
  components: {
    Step
  },
  data() {
    return {
      active: 0
    };
  },
  methods: {
    returns() {
      this.$router.push("/");
    },
    nextStep() {
      if (this.active === 0) {     
        this.active = 1;
      } else if (this.active === 1) {
        this.active = 3;
      } else {
        this.$router.push("/");
      }
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.verify-phone {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background: pink;
  .retrieve-password {
    width: 100%;
    height: 81px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
    line-height: 81px;
    .left {
      float: left;
      .logo {
        width: 288px;
        height: 47px;
        vertical-align: middle;
        margin: -10px 27px 0 34px;
      }
      .span {
        font-size: 24px;
        font-weight: 400;
        color: #333;
      }
    }
    .right {
      float: right;
      margin: 0 65px 0 0;
      .exit_icon {
        width: 24px;
        height: 24px;
        margin: 0 16px 0 0;
        cursor: pointer;
      }
      .button {
        font-size: 24px;
        font-weight: 400px;
        color: #333;
      }
    }
  }
  .step {
    width: 100%;
    // background: pink;
    .steps {
      width: 692px;
      height: 41px;
      margin: 90px auto 79px;
      /deep/ .el-step__title.is-process {
        font-size: 20px;
        color: #5473e8;
        margin: -32px 0 0 41px;
        font-weight: 500;
      }
      /deep/ .el-step.is-horizontal .el-step__line {
        width: 107px;
        height: 1px;
        background: rgba(0, 0, 0, 0.15);
      }
      /deep/ .el-step.is-horizontal .el-step__line {
        left: 145px;
      }
      /deep/ .el-step__title.is-wait {
        font-size: 20px;
        font-weight: 500;
        color: #666;
        margin: -32px 0 0 41px;
      }
      /deep/ .el-step__title.is-success {
        color: #5473e8;
        font-size: 20px;
        font-weight: 500;
        margin: -32px 0 0 41px;
      }
      // /deep/ .el-step__icon.is-text{
      //   border-color: #5A72E0;
      // }
      // /deep/ .el-icon-check:before {
      //   color: #5A72E0;
      // }
      // /deep/ .el-step__line-inner {
      //   border-color: #5A72E0;
      // }
    }
  }
}
</style>
