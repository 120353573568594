import axios from 'axios'
import store from '@/store'

// 封装ajax
const service = axios.create({
  // baseURL: '/api/', // 设置axios请求的基础的基础地
})
service.interceptors.request.use(function (config) {
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

service.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  const { data } = response
  return data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});
const request = (url, method = 'get', data) => {
  const config = {}
  data.m_id = store.state.m_id
  data.token = store.state.token
  if (method === 'get') {
    config.params = data
  } else {
    config.data = data
  }
  return service({
    url,
    method,
    ...config
  })
}
export default request
