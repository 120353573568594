import Cookies from 'js-cookie'

// token的函数
export function getToken() {
    return Cookies.get('CHAT_TOKEN')
}

export function setToken(token) {
    Cookies.set('CHAT_TOKEN', token)
}

export function removeToken() {
    Cookies.remove('CHAT_TOKEN')
}
// uid的函数
export function getChatId() {
    return Cookies.get('CHAT_ID')
}

export function setChatId(chatId) {
    Cookies.set('CHAT_ID', chatId)
}

export function removeChatId() {
    Cookies.remove('CHAT_ID')
}