import { Message } from 'element-ui'
let tools = {}

/**
 * 大小判断
 * @param a
 * @param b
 * @returns {number}
 */
tools.sortNumber = function (a, b) {
  return a - b;
}

tools.error = function (msg) {
  Message({
    message: msg,
    type: 'error',
    duration: 5 * 1000
  })
}

tools.success = function (msg) {
  Message({
    message: msg,
    type: 'success',
    duration: 5 * 1000
  })

}


tools.image2Base64=function (img) {
  let canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  let dataURL = canvas.toDataURL("image/png");
  console.log(dataURL)
  return dataURL;
}


// 合并单元格
tools.hbdyg = function () {
  setTimeout(function () {
    var list = $('.spec_input_tab_list'); //要合并的tableID  spec_input_tab_info hb_num
    var list_num = list.length;
    if (list_num > 1) {
      var last_obj = $(list[0]);
      var line_num = last_obj.find('.spec_input_tab_info').length;
      $('.spec_input_tab_info').show();
      $('.spec_input_tab_info').attr('rowspan', 1);
      for (var k = 0; k < line_num; ++k) {
        var last_info_obj = last_obj.find('.spec_input_tab_info').eq(k);
        var hb_num = 1;
        for (var i = 1; i < list_num; ++i) {
          var new_info_obj = $(list[i]).find('.spec_input_tab_info').eq(k);
          if (last_info_obj.text() == new_info_obj.text()) {
            ++hb_num;
            new_info_obj.hide();
            last_info_obj.attr('rowspan', hb_num);
          } else {
            hb_num = 1;
            last_obj = $(list[i]);
            last_info_obj = last_obj.find('.spec_input_tab_info').eq(k);
          }
        }
        last_obj = $(list[0]);
      }
    }
  }, 10);
}


export default tools
