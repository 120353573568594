<template>
  <div class="contents">
    <!-- <el-input></el-input> -->
    <input
      type="text"
      v-model="loginForm.phone"
      placeholder="请输入手机号"
      class="input"
      oninput="value=value.replace(/[^\d]/g,'')"
      maxLength="11"
    />
    <div v-if="activeName === 'password-login'">
      <!-- <el-input></el-input> -->
      <input
        type="text"
        v-model="loginForm.password"
        placeholder="请输入密码"
        class="inputs"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
      <el-button type="text" class="button" @click="forget">忘记密码</el-button>
    </div>
    <div v-else>
      <el-input
        v-model="loginForm.code"
        placeholder="请输入短信验证码"
        class="short-message-code"
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
      <el-button
        type="text"
        class="buttons"
        @click="sendCode"
        v-if="time === 0"
      >
        获取验证码
      </el-button>
      <el-button type="text" class="buttons" v-else
        >{{ this.time }} s</el-button
      >
    </div>
    <el-button type="primary" class="login" @click="login">登录</el-button>
  </div>
</template>

<script>
import tools from "@/common/js/tools";
import { login, send } from "@/api/user";
import socket from "@/common/js/socket";

export default {
  props: {
    activeName: {},
  },
  data() {
    return {
      loginForm: {
        password: "",
        phone: "",
        code: "",
      },
      time: 0,
    };
  },
  methods: {
    forget() {
      this.$router.push("/forgetpassword");
    },
    async login() {
      this.verification();
      let res = await this.$store.dispatch("login", this.loginForm);
      if (res.code * 1 === 1) {
        tools.success(res.msg);
        setTimeout(()=>{
          socket.wapLogin();
        },200)
        setTimeout(()=>{
          this.$router.push("/chat");
        },1500)

      } else {
        tools.error(res.msg);
      }
    },
    // logins() {
    //   if (this.loginForm.phone === "" || this.loginForm.password === "") {
    //     tools.error("请输入手机号码和密码");
    //     return;
    //   }
    // },
    async sendCode() {
      if (this.loginForm.phone === "") {
        tools.error("请输入手机号码");
        return;
      }
      const result = await send({
        phone: this.loginForm.phone,
        send_type: "login",
      });
      console.log("请求数据---------------", result);

      if (this.time === 0) {
        tools.success("发送成功");
        this.time = 60;
        let that = this;
        const timer = setInterval(() => {
          that.time--;
          if (that.time === 0) {
            clearInterval(timer);
          }
        }, 1000);
      } else {
        tools.error("验证码获取失败");
      }
    },
    verification() {
      if (this.activeName === "password-login") {
        if (this.loginForm.password === "" || this.loginForm.phone === "") {
          tools.error("请输入手机号码和密码");
          return;
        }
      } else {
        if (this.loginForm.code === "" || this.loginForm.phone === "") {
          tools.error("请输入手机号码和验证码");
          return;
        }
      }
    },
    /***
     * 此方法已经封装至vuex
     */
    // async login() {
    //   //用户登录
    //   const res = await login({'phone':this.phone,'code':this.code,'password':this.password})
    //   // const res = await login({'phone':this.phone,'password':this.password})
    //   console.log(res);
    //   if (res.code === 1) {
    //     // context.commit('setToken', res.data)
    //     // context.commit('setUid', res.data)
    //     this.$router.push('/chat')
    //   } else {
    //     tools.error(res.msg)
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
.contents {
  width: 515px;
  height: auto;
  margin: 0 auto;
  /deep/ .el-input__inner {
    height: 81px;
    background: #f7f8fa;
    border: none;
    border-radius: 40px;
    padding-left: 45px;
    font-size: 24px;
  }
  .input {
    width: 440px;
    height: 81px;
    border: none;
    background: #f7f8fa;
    border-radius: 45px;
    padding: 0 30px 0 45px;
    margin: 0 0 51px 0;
    font-size: 24px;
    outline: none;
    color: #606266;
  }

  .inputs {
    width: 440px;
    height: 81px;
    border: none;
    background: #f7f8fa;
    border-radius: 45px;
    padding: 0 30px 0 45px;
    font-size: 24px;
    outline: none;
    color: #606266;
  }

  .button {
    color: #333333;
    font-size: 24px;
    margin: 31px 0 73px 50px;
    display: block;
  }

  .login {
    width: 100%;
    height: 80px;
    border-radius: 40px;
    font-size: 24px;
    color: #fff;
    background: linear-gradient(129deg, #4b86fa 0%, #3f7fff 100%);
  }

  .short-message-code {
    width: 345px;
    height: 81px;
    margin: 0 45px 130px 0;
    display: inline-block;
  }

  .buttons {
    color: #333333;
    font-size: 24px;
  }
}

/deep/ input::-webkit-input-placeholder {
  color: #999999;
  font-size: 24px;
}

// input::-moz-placeholder {
//   color: red;
// }
// input::-moz-input-placeholder {
//   color: red;
// }
// input::-ms-input-placeholder {
//   color: red;
// }
</style>
