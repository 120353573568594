import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login/login'
import ForgetPassword from '@/views/login/forget-password'
import Chat from '@/views/chat'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat
  }

]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  next() // 通过
})

export default router
